import {LEVEL_INFO} from '@rollbar/react'
import {Configuration} from "rollbar";
import packageInfo from './../../package.json'

const environments: string[] = [
    'staging',
    'production',
]

const config: Configuration = {
    accessToken: process.env['REACT_APP_ROLLBAR_TOKEN'],
    environment: process.env['REACT_APP_ENV'],
    enabled: environments.includes(process.env['REACT_APP_ENV'] || ''),
    captureUncaught: true,
    captureUnhandledRejections: true,
    reportLevel: LEVEL_INFO,
    payload: {
        client: {
            javascript: {
                code_version: packageInfo.version
            },
        },
        environment: process.env['REACT_APP_ENV'],
    }
}

export default config
