import React, {useEffect, useRef} from 'react';
import strawberrySvg from '../../resources/outline.svg';

export const PatternCanvas: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const img = new Image();
        img.src = strawberrySvg;

        const baseWidth = 50;
        const minScaleFactor = 0.75;
        const maxScaleFactor = 1.25;
        const rotationRange = 45;
        const positionOffset = 20;

        const resizeAndDraw = () => {
            if (!canvas || !ctx) return;

            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;

            const initialScale = baseWidth / img.width;
            const imgWidth = baseWidth;
            const imgHeight = img.height * initialScale;

            const cols = Math.ceil(canvas.width / imgWidth);
            const rows = Math.ceil(canvas.height / imgHeight);

            for (let y = 0; y < rows; y++) {
                for (let x = 0; x < cols; x++) {
                    if (Math.round(Math.random() / 1.5)) continue;
                    const scaleFactor = randRange(minScaleFactor, maxScaleFactor);
                    const scaledW = imgWidth * scaleFactor;
                    const scaledH = imgHeight * scaleFactor;
                    const rotationDeg = randRange(-rotationRange, rotationRange);
                    const rotationRad = (rotationDeg * Math.PI) / 180;
                    const baseX = x * imgWidth;
                    const baseY = y * imgHeight;
                    const offsetX = randRange(-positionOffset, positionOffset);
                    const offsetY = randRange(-positionOffset, positionOffset);

                    ctx.save();
                    ctx.translate(baseX + scaledW / 2 + offsetX, baseY + scaledH / 2 + offsetY);
                    ctx.rotate(rotationRad);
                    ctx.globalAlpha = 0.55 * scaleFactor;
                    ctx.drawImage(img, -scaledW / 2, -scaledH / 2, scaledW, scaledH);
                    ctx.restore();
                }
            }
        };

        const randRange = (min: number, max: number): number => Math.random() * (max - min) + min;

        img.onload = () => {
            resizeAndDraw();
            window.addEventListener('resize', resizeAndDraw);
        };

        return () => {
            window.removeEventListener('resize', resizeAndDraw);
        };
    }, []);

    return <canvas
        ref={canvasRef}
        style={{
            display: 'block',
            margin: 0,
            padding: 0,
            overflow: 'hidden',
            background: 'transparent',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 0
        }}
    />;
};
